const enums = {
    roles: {
        'Admin': 1,
        'Exporter': 2,
        'DA': 3,
        'Enrollment_Executive': 4,
        'AD': 5,
        'DD': 6,
        'Director': 7,
        'DG': 8
    },
    Designations: {
        'None': null,
        'Dealing_Assistant': 1,
        'Enrollment_Executive': 2,
        'Assistant_Director': 3,
        'Deputy_Director': 4,
        'Programmer': 5,
        'Director': 6,
        'Secretary': 7,
        'Director_General': 8,
        'Vice_Chairman': 9,
        'Deputy_Secretary': 10,
        'Research_Officer': 11,
        'Information_Officer': 12,
        'Account_Officer': 13,
        'Technical_Officer': 14,
        'Investigator': 15,
        'Computer_Operator': 16,
        'UDA': 17,
        'LDA': 18,
        'Executive_Assistant': 19,
        'Draftsman': 20,
        'Receptionist': 21,
        'Administration_Officer': 22,
        'Project_Officer': 23,
        'PA': 24,
        'Trade_Analyst': 25,
        'Analyst': 26,
        'Telex_Operator': 27,
        'Sales_Officer': 28,
        'Software_Admin': 50,
        'Commercial_Officer': 100,
        'Manager': 101,
        'Managing_Director': 102,
        'General_Manager': 103,
        'Commercial_Executive': 104,
        'Commercial_Manager': 105,
        'Proprietor': 106,
        'Chairman': 107,
        'Dir': 108,
    },
    ExporterTypes: {
        'None': 0,
        'Textile': 1,
        'NonTextile': 2,
        'Commodities' : 3,
        'Administration' : 4,
        'Fair' : 5,
        'Information' : 6,
        'Finance' : 7,
        'Project' : 8
    },
    ApplicationTypes: {
        'Registration': 1,
        'Renewal': 2,
        'Address_Change' : 3,
    },
    ApplicationAttachmentTypes:{
        'ExporterPhoto': 1,
        'TradeLicense': 2,
        'ERCFile': 3,
        'AssociationCertificate': 4,
        'VATCertificate': 5,
        'TINCertificate': 6,
        'BondedWarehouseLicense': 7,
        'FireInsurance': 8,
        'WorkerGroupInsurance': 9,
        'BIDAEPZRegLetter': 10,
        'MemorandumOfAssociation': 11,
        'FactoryRentOwnershipDocument': 12,
        'FactoryBuildingDesignApproval': 13,
        'SignedApplicationForm': 14,
    },
    ApplicationStatuses:{
        'New' : 1,
        'DA_desk' : 2,
        'Enrollment_Executive_Desk' : 3,
        'AD_desk' : 4,
        'Director_desk' : 5,
        'Team_Approval' : 6,
        'DG_desk' : 7,
        'DD_Desk' : 8,
        'Inspection' : 9,
        //'Inspection_Submitted': 10,
        'Report_Submitted' : 11,
        'Approved' : 12,
        'Rejected' : 13,
        'Cancelled' : 100,
    },
    ServiceOffices: {
        None: 0,
        Dhaka: 1,
        Narayanganj: 2,
        Chattogram: 3,
        Cumilla: 4,
        Khulna: 5,
        Rajshahi: 6,
        Sylhet: 7,
    },

    PaymentMethods:{
        Payslip: "payslip",
        SonaliBankPaymentGateway: "spg",
    },
    PaymentInstruments:{
        SbCashCounter: "A01",
        SbAccountsTransfer: "A02",
        SonaliCash: "M01",
        SureCash: "M02",
        Rocket: "M03",
        bKash: "M04",
        Nagad: "M08",
        SonaliPrePaidCard: "C01",
        VISA: "C02",
        Master: "C03",
        QCash: "C04",
        DBBLNexus: "C07",
        CityVisa: "C08",
        CityMaster: "C09",
        CityAmex: "C10",
        Upay: "M09",
        Tap: "M10",
        EPBBrac: "EPBBrac"
    },
    SvFormIncentiveStatuses:{
        'Draft' : 1,
        'Submitted' : 2,
        'Verified' : 3,
        'Invalid' : 4,
        'DA_Desk' : 5,
        'AD_desk' : 6,
        'Director_desk' : 7,
        'DD_Desk' : 8,
        'DG_desk' : 9,
        'VC_Desk' : 10,
        'Approved' : 11,
        'Cancelled' : 100,
    },
    SvFormCOStatuses:{
        'Draft' : 1,
        'Submitted' : 2,
        'Verified' : 3,
        'Invalid' : 4,
        'Cancelled' : 100,
    },
    SvFormTypes:{
        'Incentive' : 1,
        'CO' : 2,
    },
    unitTypes:{
        'Piece': 1,
        'Feet': 2,
        'Pairs': 3,
        'KGM': 4,
        'Metre': 5,
        'Centimetre': 6,
        'Litre': 7,
        'SquareFeet': 8,
        'SquareMetre': 9,
    },
    currencies:{
        'USD': 1,
        'EUR': 2,
        'GBP': 3,
        'JPY': 4,
        'CNY': 5,
        'BDT': 6,
    },
};
export default function getEnums() {
    return enums;
}