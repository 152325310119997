<template>
    <v-app>
        <v-main class="public-page-bg">

            <v-container fill-height fluid>
                <v-row>
                    <v-col sm="8" offset-xl="2" offset-lg="2" offset-md="2">
                        <v-card :loading="loading" class="mx-auto my-12 rounded pa-5" elevation="0">
                            <template slot="progress">
                                <v-progress-linear
                                    :color="$globalSettings.progressbarClasses"
                                    height="10"
                                    indeterminate
                                ></v-progress-linear>
                            </template>

                            <v-card-text v-if="formData.StatusId >= $globalEnums.ApplicationStatuses.Approved">
                                <v-row>
                                    <v-col cols="12" sm="4" style="text-align: left;" class="font-size-14 pb-0">
    <!--                                    No: <span class="font-bold">{{ $globalHelpers.getFormattedDate(formData.Created, 'YYYY-MM-DD H:mm:ss', 'DDMMYYYY') + formData.Id}}</span>-->
                                        Application No: <span class="font-bold">{{ formData.Id}}</span>
                                    </v-col>
                                </v-row>
                                <v-row class="mt-0 mb-3">
                                    <v-col cols="12" sm="12" class="text-center">
                                        <img  src="/images/epb-round.png" style="height: 70px;">
                                        <div class="pdftemplate-heading-title pdf-margin-top-2">Export Promotion Bureau</div>
                                        <div class="font-size-18 pdf-margin-top-2">Enrolment Certificate</div>
                                        <div class="mt-2">Reg. No: <span class="font-bold">{{ formData.Username }}</span></div>
                                    </v-col>
                                </v-row>
                                This is to Certify that <span class="font-bold">{{ formData.OrganizationName }}</span> has been enrolled with <span class="font-bold">Export Promotion Bureau</span>.
                                <div class="pdf-margin-top-3 pdf-margin-bottom-2">Exporter's Particulars</div>

                                <table style="width: 100%; font-size: 14px;">
                                    <tr>
                                        <td>1. Organization</td><td>: <span class="font-bold">{{ formData.OrganizationName }}</span></td>
                                    </tr>
                                    <tr>
                                        <td style="width: 30%;">2. Office Address</td><td>: <span class="font-bold">{{ formData.OfficeAddress }}, {{formData.OfficeThanaId? $globalHelpers.getThanaName(formData.OfficeThanaId): '' }}, {{ formData.OfficeDistrictId? $globalHelpers.getDistrictName(formData.OfficeDistrictId): ''}}</span></td>
                                    </tr>
                                    <tr>
                                        <td>3. Factory Address</td><td>: <span class="font-bold">{{ formData.FactoryAddress }}, {{ formData.FactoryThanaId? $globalHelpers.getThanaName(formData.FactoryThanaId) : ''}}, {{ formData.FactoryDistrictId? $globalHelpers.getDistrictName(formData.FactoryDistrictId): ''}}</span></td>
                                    </tr>
                                    <tr>
                                        <td>4. Rex No</td><td>: <span class="font-bold">{{ formData.RexNo }}</span></td>
                                    </tr>
                                    <tr>
                                        <td>5. BIN No</td><td>: <span class="font-bold">{{ formData.BINNo }}</span></td>
                                    </tr>
                                    <tr>
                                        <td>6. TIN No</td><td>: <span class="font-bold">{{ formData.TINNo }}</span></td>
                                    </tr>
                                    <tr>
                                        <td>7. Type of Factory</td>
                                        <td>:
                                            <span class="font-bold" v-if="formData.FactoryTypes">
                                                <span v-if="formData.FactoryTypes.length > 0">
                                                    <span v-for="(eaInspectionFactoryType, i) in formData.FactoryTypes" :key="i" class="text-underline pdf-margin-right-2">{{ eaInspectionFactoryType.FactoryType.Label }}
                                                    </span>
                                                </span>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>8. Service Office</td><td>: <span class="font-bold">{{ $globalHelpers.getServiceOfficeName(formData.ServiceOfficeId) }}</span></td>
                                    </tr>
                                </table>
                                <div class="pdf-margin-top-8">This Certificate is valid for {{ formData.PaymentYears > 0? formData.PaymentYears: '1' }} Year(s), from <span class="font-bold">{{ formData.ValidFrom > 0? '01.07.' + formData.ValidFrom: $globalHelpers.getFormattedDate(formData.ValidFromDate, '', 'DD.MM.YYYY') }}</span> to <span class="font-bold">{{ formData.ValidTill > 0? '30.06.'+ formData.ValidTill: $globalHelpers.getFormattedDate(formData.ValidTillDate, '', 'DD.MM.YYYY') }}</span>. After expiry, this certificate has to be renewed for the next period.</div>


                                <v-row class="">
                                    <v-col cols="12" sm="12" class="pt-10 font-size-14">
                                        <span style="font-size: 14px;">Approved By</span>: {{ $globalHelpers.getUserRoleName(formData.ApprovedByRoleId) }}{{ formData.ExporterType? ', ' + $globalHelpers.getExporterTypesName(formData.ExporterType): '' }}, Export Promotion Bureau, {{ $globalHelpers.getServiceOfficeName(formData.ServiceOfficeId) }}

                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-text v-else>
                                <v-icon style="color: red;" class="mr-3" dense>fas fa-triangle-exclamation</v-icon> <i style="color: red;" class="font-size-20">Invalid Exporter Registration Certificate.</i>
                                <div class="mt-4 font-size-14">Please contact support or Export Promotion Bureau.</div>
                            </v-card-text>

                            <div class="pt-15">
                                <v-btn depressed @click="$router.push({name: 'Login'})" class="hidden-print-only"><v-icon dense>fas fa-caret-left</v-icon> Home</v-btn>
                                <v-btn depressed @click="print()" class="ml-4 hidden-print-only" v-if="formData.StatusId >= $globalEnums.ApplicationStatuses.Approved"><v-icon dense>fas fa-print</v-icon> Print</v-btn>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>

            </v-container>
        </v-main>
    </v-app>
</template>

<script>
//import Vue from 'vue';


import Vue from "vue";

export default {
    name: "Certificate_view",
    data: () => ({
        loading: false,

        formActions:{

        },
        formData: {}
    }),
    components: {

    },
    methods: {
        loadSavedData: async function () {
            this.loading = true;
            await this.$axios.get(this.$globalSettings.api.endpoints.ea.getPublicEA, { params: { id: this.$route.params.id} }).then(async (response) => {
                this.loading = false;
                if (response.data.Status === 'success') {
                    this.formData = response.data.Data;

                    //console.log(this.formData.ValidFrom);
                    /*if(this.formData.ValidFrom === 0){
                        this.formData.ValidFrom = Number(this.$globalHelpers.getDateToday('YYYY'));
                    }*/
                    //console.log("saved data loaded");
                }else{
                    Vue.$toast.open({message: response.data.Msg, type: response.data.Status});
                }
            }).catch((error) => {
                this.loading = false;
                this.$globalHelpers.processHttpErrorsToast(error);
            });
        },
        print: function (){
            window.print();
        },
    },
    async created() {
        await this.loadSavedData();
    }
}
</script>

<style scoped>
.left-section{
    position: relative;
    background-image: url('../../assets/images/loginbg.jpg') ;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.left-section-content{
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
}
.left-app-texts{
    margin-top: 30px;
}
.left-app-name{
    color: white;
    font-size: 24px;
    font-weight: bold;
    font-family: "Source Sans Pro", sans-serif;
    border-radius: 5px;
}
.right-section{
    padding-top: 100px !important;
    padding-bottom: 100px !important;
}
</style>