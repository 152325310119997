<template>
    <v-app>

        <v-navigation-drawer app :clipped="clipped" v-model="drawer" enable-resize-watcher class="contrast-bg">
            <v-list-item class="accent-bg white--text" @click="navigate('Login', {})">
                <v-list-item-content>
                    <v-row no-gutters class="pt-2 pb-1">
                        <v-col sm="2">
                            <v-img contain height="30" :src="$globalSettings.app_icon"></v-img>
                        </v-col>
                        <v-col sm="10" class="text-center pt-1 hidden-xs-only">
                            <span class="v-list-item--highlighted">{{ $globalSettings.app_name }}</span>
                        </v-col>
                    </v-row>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item class="pt-1 pb-1">
                <v-list-item-content class="white--text">
                    <v-row no-gutters class="pt-2 pb-1">
                        <v-col sm="2">
                            <v-img contain width="30" :src="profile_pic"></v-img>
                        </v-col>
                        <v-col sm="10" class="pl-3 hidden-xs-only">
                            <div class="text-uppercase">
                                {{user_name}}
                            </div>
                            <div class="font-italic text-sm-caption">
                                {{user_organization_name}}
                            </div>
                            <div class="text-sm-caption">
<!--                                {{ user_role_name + (user_exporter_type? ', ' + $globalHelpers.getExporterTypesName(user_exporter_type): '')}}-->
                                {{ (user_designation_id? user_designation_name: '') + (user_exporter_type? ', ' + $globalHelpers.getExporterTypesName(user_exporter_type): '')}}<br>
                                {{ user_role_id !== $globalEnums.roles.Exporter? $globalHelpers.getServiceOfficeName(user_service_office_id): '' }}
                            </div>
                        </v-col>
                    </v-row>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>

            <v-list dense nav class="left-nav-list">
                <!--Application-->
                <v-list-group :value="$route.name === 'App.EA.List' || $route.name === 'App.EA'" prepend-icon="fa-file-signature">
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title class="white--text">Applications</v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <v-list-item link class="theme--dark" v-if="(user_role_id === $globalEnums.roles.Exporter && user_username == null) || user_role_id !== $globalEnums.roles.Exporter" :class="$route.name === 'App.EA.List' && $route.params.type === 'reg' && $route.params.status === 'pending' ? 'accent-bg pl-10' : 'pl-10'" v-on:click="navigate('App.EA.List', {type: 'reg', status: 'pending'})">
                        <v-list-item-icon>
                            <v-icon class="white--text">fas fa-user-plus</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Registration</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link class="theme--dark" :class="$route.name === 'App.EA.List' && $route.params.type === 'ren' && $route.params.status === 'pending' ? 'accent-bg pl-10' : 'pl-10'" v-on:click="navigate('App.EA.List', {type: 'ren', status: 'pending'})">
                        <v-list-item-icon>
                            <v-icon class="white--text">fas fa-user-clock</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Renewal</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link class="theme--dark" :class="$route.name === 'App.EA.List' && $route.params.type === 'ac' && $route.params.status === 'pending' ? 'accent-bg pl-10' : 'pl-10'" v-on:click="navigate('App.EA.List', {type: 'ac', status: 'pending'})">
                        <v-list-item-icon>
                            <v-icon class="white--text">fas fa-map-location-dot</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Information Change</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link class="theme--dark" :class="$route.name === 'App.EA.List' && $route.params.type === 'all' && $route.params.status === 'inspection' ? 'accent-bg pl-10' : 'pl-10'" v-on:click="navigate('App.EA.List', {type: 'all', status: 'inspection'})">
                        <v-list-item-icon>
                            <v-icon class="white--text">fas fa-industry</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Inspection</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link class="theme--dark" :class="$route.name === 'App.EA.List' && $route.params.type === 'all' && $route.params.status === 'completed' ? 'accent-bg pl-10' : 'pl-10'" v-on:click="navigate('App.EA.List', {type: 'all', status: 'completed'})">
                        <v-list-item-icon>
                            <v-icon class="white--text">fas fa-circle-check</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Completed</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>

                <!-- Services -->
                <v-list-group :value="$route.name === 'App.SvForm.List' || $route.name === 'App.SvForm'" prepend-icon="fas fa-table-cells">
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title class="white--text">Services</v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <v-list-item link class="theme--dark" :class="$route.name === 'App.SvForm.List' && $route.params.type === 'incentive' ? 'accent-bg pl-10' : 'pl-10'" v-on:click="navigate('App.SvForm.List', {type: 'incentive'})">
                        <v-list-item-icon>
                            <v-icon class="white--text">fas fa-file-invoice-dollar</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Cash Incentive</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link class="theme--dark" :class="$route.name === 'App.SvForm.List' && $route.params.type === 'co'? 'accent-bg pl-10' : 'pl-10'" v-on:click="navigate('App.SvForm.List', {type: 'co'})">
                        <v-list-item-icon>
                            <v-icon class="white--text">fas fa-ship</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>CO</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>

                <v-list-group :value="$route.name === 'App.User.List'" prepend-icon="fa-solid fa-users" v-if="$globalHelpers.getAuthStoreValue('user_role_id') !== $globalEnums.roles.Exporter">
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title class="white--text">Users</v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <v-list-item link class="theme--dark" v-on:click="navigate('App.User.List', {type: 'exporters'})" :class="$route.name === 'App.User.List' && $route.params.type === 'exporters'? 'accent-bg pl-10' : 'pl-10'" v-if="$globalHelpers.getAuthStoreValue('user_role_id') !== $globalEnums.roles.Exporter">
                        <v-list-item-icon>
                            <v-icon class="white--text">fas fa-user-group</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Exporter Users</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link class="theme--dark" v-on:click="navigate('App.User.List', {type: 'users'})" :class="$route.name === 'App.User.List' && $route.params.type === 'users'? 'accent-bg pl-10' : 'pl-10'" v-if="$globalHelpers.getAuthStoreValue('user_role_id') === $globalEnums.roles.Admin">
                        <v-list-item-icon>
                            <v-icon class="white--text">fas fa-id-card</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>EPB Users</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>


                <v-list-item link class="theme--dark" v-on:click="navigate('App.FactoryType.List', {type: 'all'})" :class="$route.name === 'App.FactoryType.List'? 'accent-bg' : ''" v-if="$globalHelpers.getAuthStoreValue('user_role_id') === $globalEnums.roles.Admin">
                    <v-list-item-icon>
                        <v-icon class="white--text">fas fa-industry</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Factory Types</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item link class="theme--dark" v-on:click="navigate('App.Report.List')" :class="$route.name === 'App.Report.List'? 'accent-bg' : ''" v-if="$globalHelpers.getAuthStoreValue('user_role_id') !== $globalEnums.roles.Exporter">
                    <v-list-item-icon>
                        <v-icon class="white--text">fas fa-list-check</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Reports</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>


                <!--<v-list-item link class="theme&#45;&#45;dark" v-on:click="logout()">
                    <v-list-item-icon>
                        <v-icon class="white&#45;&#45;text">fas fa-right-from-bracket</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Logout</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>-->
            </v-list>
        </v-navigation-drawer>

        <v-app-bar fixed app class="accent-bg white--text" :clipped-left="clipped" elevate-on-scroll>
            <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="white--text"></v-app-bar-nav-icon>
            <v-spacer></v-spacer>

            <v-menu left bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn dark v-bind="attrs" v-on="on" plain>
                        {{ user_username? user_username: (user_email? user_email: user_name) }}
                        <v-icon x-small>fas fa-angle-down</v-icon>
                    </v-btn>
                </template>

                <v-list dense>
                    <v-list-item v-on:click="navigate('App.User', {id: user_id})">
                        <v-list-item-title><v-icon class="mr-2" small>fas fa-user-gear</v-icon> Account</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-on:click="$globalHelpers.logout()">
                        <v-list-item-title><v-icon class="mr-2" small>fas fa-right-from-bracket</v-icon> Logout</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>

        <!-- Sizes your content based upon application components -->
        <v-main>

            <!-- Provides the application the proper gutter -->
            <v-container fluid>

                <!-- If using vue-router -->
                <v-slide-x-transition mode="out-in">
                    <keep-alive include="Dashboard_view">
                        <router-view></router-view>
                    </keep-alive>
                </v-slide-x-transition>
            </v-container>
        </v-main>

        <v-footer app>
            <!-- -->
        </v-footer>
    </v-app>
</template>

<script>
    //import Vue from 'vue';
    export default {
        name: "Layout_view",
        data: function () {
            return{
                drawer: true,
                clipped: false,
                user_id: null,
                user_name: null,
                user_organization_name: null,
                user_role_id: null,
                user_role_name: null,
                user_designation_id: null,
                user_designation_name: null,
                user_email: null,
                user_username: null,
                user_exporter_type: null,
                user_service_office_id: null,
                user_profile_pic: null
            }
        },
        computed: {
            profile_pic: function (){
                if(!this.$globalHelpers.isEmptyOrUndefined(this.user_profile_pic)){
                    return this.$apiBaseURL + this.$globalSettings.api.endpoints.media.profilePics + this.user_profile_pic;
                }
                return '/images/user.png';
            }
        },
        methods:{
            navigate: async function (routeName, params) {
                if(routeName !== '#' && !(this.$route.name === routeName && JSON.stringify(this.$route.params) === JSON.stringify(params) ) ) {
                    await this.$router.push({name: routeName, params: params});
                }
            },
        },
        created() {
            this.user_id = this.$globalHelpers.getAuthStoreValue('user_id');
            this.user_name = this.$globalHelpers.getAuthStoreValue('user_name');
            this.user_organization_name = this.$globalHelpers.getAuthStoreValue('user_organization_name');
            this.user_role_id = this.$globalHelpers.getAuthStoreValue('user_role_id');
            this.user_role_name = this.$globalHelpers.getUserRoleName(this.user_role_id);
            this.user_designation_id = this.$globalHelpers.getAuthStoreValue('user_designation_id');
            this.user_designation_name = this.$globalHelpers.getUserDesignationName(this.user_designation_id);
            this.user_email = this.$globalHelpers.getAuthStoreValue('user_email');
            this.user_username = this.$globalHelpers.getAuthStoreValue('user_username');
            this.user_exporter_type = this.$globalHelpers.getAuthStoreValue('user_exporter_type');
            this.user_service_office_id = this.$globalHelpers.getAuthStoreValue('user_service_office_id');
            this.user_profile_pic = this.$globalHelpers.getAuthStoreValue('user_profile_pic');
            //console.log(this.user_designation_id);
        }
    }
</script>

<style scoped>

</style>